body {
    margin: 0;
    font-family: 'Arial', sans-serif;
  }
  
  .coming-soon-container {
    position: relative;
    width: 100vw;
    height: 100vh;
    background-image: url('../public/background.jpg'); /* Add your image here */
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
  }
  
  .content {
    z-index: 2;
  }
  
  h1 {
    font-size: 3rem;
    margin-bottom: 0.5rem;
  }
  
  p {
    font-size: 1.2rem;
    margin-bottom: 2rem;
  }
  
  .countdown {
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }
  
  .social-icons a {
    color: white;
    margin: 0 15px;
    text-decoration: none;
    font-size: 1.2rem;
    transition: color 0.3s;
  }
  
  .social-icons a:hover {
    color: #FFD700;
  }
  